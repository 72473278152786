.justifyTable {
    color: #2c3439;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.justifyRow {
    margin-top: 5px;
    margin-bottom: 5px;
}

.separator {
    border-bottom: 1px solid #e5ebed;
    margin-top: 14px;
    margin-bottom: 14px;
}

.fontNormal {
    color: #2c3439;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.fontNormalBold {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
}

.fontSoft {
    color: rgba(44, 52, 57, 0.6);
}

.fontLarge {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

.fontSmall {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
}

.total {
    color: #6ba342;
}

.fontStrikethrough {
    text-decoration: line-through;
}

.tooltipWrapper {
    position: relative;
    display: inline-block;
}

.tooltipContentHidden {
    width: 384px;
    padding: 17px;
    border: 1px solid #ccd6da;
    border-radius: 5px;
    border-left: 4px solid #023349;
    background-color: #ffffff;
    visibility: hidden;
    text-align: left;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    left: 50%;
    transform: translateX(-50%);
}

.tooltipContentVisible {
    opacity: 1 !important;
    visibility: visible !important;
    z-index: 10;
}
