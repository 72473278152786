.optionsContainer label {
    margin-top: 15px !important;
    color: #373737;
    line-height: 0.9 !important;
    font-family: Nunito Sans, sans-serif;
    font-weight: 300;
}

.title {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #2c3439;
    margin-top: 10px;
}

.input {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #b2c2c8;
    width: 126px;
    height: 40px;
    padding-right: 5px;
    outline: #b2c2c8;
    font-family: Nunito Sans, sans-serif;
    font-weight: 300 !important;
    color: #2c3439;
}

.amountOptionsContainer {
    margin: 0.3rem 0 1rem 1.4rem;
    position: relative;
}

.amountOptionsContainerTripProtection {
    margin: 0.3rem 0 0 0;
    position: relative;
}

.amountOptionsContainer > input {
    padding-left: 0.5rem;
    padding-right: 0;
}

.amountOptionsContainerTripProtection > input {
    padding-left: 0.5rem;
    padding-right: 0;
}

.authorizeTitle {
    margin-top: 20px;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #2c3439;
}

.buttonContainer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    width: 232px;

    button {
        width: 100% !important;
        margin-right: 5px;
    }
}

.notesContainer,
.searchBarContainer {
    margin-top: 2rem;

    span {
        font-family: Nunito Sans, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #2c3439;
    }

    div {
        textarea {
            outline: #b2c2c8;
            padding: 10px;
            font-family: Nunito Sans, sans-serif;
            font-weight: 300 !important;
            color: #2c3439;
        }
    }

    input {
        margin-top: 0.5rem;
    }
}

input[type="text"]::placeholder {
    color: #2c3439;
}

.icon {
    filter: invert(41%) sepia(100%) saturate(3.5) hue-rotate(45deg) drop-shadow(20px 20px 23px black);
}

.reseditLink {
    color: #0078ab;
    cursor: pointer;
}

.modal {
    word-break: break-word;
    text-align: justify;
}

.dangerText {
    color: #d43354;
}

.requiredValues {
    font-size: 0.8rem;
    color: #2c3439;
    margin-top: 1rem;
}

.customTpTitle {
    margin-top: 1rem;
    font-family: Nunito Sans, sans-serif;
    font-weight: 300 !important;
}
