.cancellationScheduleBox {
    position: absolute;
    background: #f5f7f8;
    border-radius: 4.005px;
    padding: 24px 0px 24px 31px;
    width: 350px;
}

.title {
    margin-bottom: 32px;
}

.additional {
    padding: 8px 0px;
}

.additionalInfoDesc {
    color: #2c3439 !important;
    vertical-align: middle;
}

.additionalInfoIconBox {
    width: 20px;
    margin-right: 10px;
    position: relative;
    display: flex;
}

.additionalInfoIcon {
    margin: auto;
    //position: absolute;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
}
