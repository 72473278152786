.bodyEmail {
    border-radius: 0 0 5px 5px;
    color: black;
    border-left: 1px solid #b2c2c8;
    border-right: 1px solid #b2c2c8;
    border-bottom: 1px solid #b2c2c8;
    background-color: #f5f7f8;
    p {
        font-size: 16px;
        margin-bottom: 9px;
    }
    a {
        font-weight: 600;
        color: #0078aa;
    }
}

.emailContent {
    width: 528px;
    margin: auto;
    padding: 42px 0;
}

.textAreaNote {
    width: 515px;
    height: 81px;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 8px 12px;
    color: #2c3439;
}

.logo {
    margin-bottom: 40px;
    width: 186px;
}

.title {
    font-weight: bold;
    font-size: 27px !important;
    color: black;
    margin-bottom: 14px;
}

.dateLabel {
    color: #4c5357;
    font-size: 14px;
}

.noteLabel {
    font-size: 16px;
    font-weight: 600;
}

.subtitle {
    font-size: 20px !important;
    margin-bottom: 26px;
}

.table {
    display: flex;
    flex-direction: column;
    td {
        width: 274px;
    }
    tr {
        margin-bottom: 20px;
    }
}

.tableRow {
    display: flex;
}

.unitName {
    font-weight: bold;
    font-size: 18px;
}
.reservationCode {
    font-size: 14px;
}

.tableRightColumn {
    padding-left: 54px;
}
