.buttonsBox {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 632px;
    margin-left: 19px;
    display: flex;
    justify-content: flex-end;
}

.buttonsBox button {
    padding: 8px 24px 8px 24px;
}

.buttonsBox :last-child {
    margin-left: 2rem;
}

.modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
    z-index: 10000000 !important;
}

.modalContent button {
    padding: 8px 50px 8px 50px;
    background-color: #6ba342;
    color: #fff;
    border: none;
    margin-bottom: 0.6rem;
}

.modalContent a {
    margin-top: 1rem;
}

.modalContent label {
    margin: 2px 0 0 5px !important;
}

.modalContent p {
    word-break: break-word;
    text-align: center;
}

.toRegretText {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0078ab !important;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
}

.h3 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 24px;
    font-family: "wigrum-bold", sans-serif;
    font-weight: 600;
    color: #003349;
}

.cancellingMessage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.message {
    margin-left: 1.5rem;
}

.titleMessage {
    word-break: break-word;
}

.descriptionMessage {
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.checkDontAsk {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}
