.searchBarContainer {
    margin-top: 0.6rem;
    z-index: 10000000000;
}

.title {
    font-size: 1rem;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #2c3439;
}
