.titleAttachment {
    display: flex;
    justify-content: space-between;
}

.segmentedBorder {
    box-sizing: border-box;
    width: 569px;
    height: 244px;
    left: 246px;
    top: 1157px;
    background: #f5f7f8;
    border: 2px dashed #ccd6db;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .uploadContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            width: 30px;
            height: 26px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .uploadText {
            width: 258px;
            height: 48px;
            left: 402px;
            top: 1222px;
            font-family: "Public Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #51585c;
        }
    }

    .lineContainer {
        display: flex;
        align-items: center;
        margin: 16px 0;

        .line {
            width: 56.22px;
            left: 459px;
            margin-left: 10px;
            margin-right: 10px;
            border: 1px solid #707579;
        }

        .orText {
            font-family: "Public Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #000000;
        }
    }

    .browseButton {
        background: #ffffff;
        border: 1px solid #0078ab;
        border-radius: 4px;
        font-family: "Public Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0078ab;
        flex: none;
        order: 1;
        flex-grow: 0;
        padding: 8px 20px;
    }
}

.attachmentsView {
    width: 569px;
    height: 34px;
    left: 248px;
    top: 835px;
    background: #e5ebed;
    box-shadow: 0px -1px 0px #b2c2c8, 0px 1px 0px #b2c2c8;
    display: flex;
    align-items: center;

    span {
        margin-left: 10px;
        font-family: "Public Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
    }
}

.attachmentsDetail {
    margin-top: 10px;
    width: 569px;
    height: 40px;
    left: 248px;
    top: 879px;
    background: #f5f7f8;
    border-radius: 4px;
    display: flex;
    align-items: center;

    span {
        margin-left: 10px;
        font-family: "Public Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #0078ab;
    }

    .iconsContainer {
        margin-left: auto;
        margin-right: 10px;
    }

    .iconButton {
        align-items: flex-end;
        background: none;
        border: none;
        cursor: pointer;
        margin-left: 10px;
    }
}
