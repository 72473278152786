// Override default variables before the import
$body-bg: #000;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";
@import "styles/_brand-colors";
@import "styles/_font-styles";

.innerMargin {
    margin-left: 62px;
    margin-right: 62px;
    margin-top: 20px;
}

.innerMarginBox {
    margin-left: 19px;
    margin-bottom: 19px;
}

.box {
    padding: 24px 31px;
    background-color: white;
    border-radius: 5px;
    width: 632px;
    margin-left: 19px;
    margin-bottom: 16px;
}

.boxTitle {
    font-family: "Wigrum", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24.03px;
    line-height: 29px;
    letter-spacing: -0.126474px;
    color: #2c3439;
    margin-bottom: 20px;
}

.flex {
    display: flex;
}

.flexCenterHorizontalEnd {
    display: flex;
    align-items: end;
}

.flexCenterVertical {
    display: flex;
    justify-content: center;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.columnFlexAbout {
    display: flex;
    flex-direction: column;
    min-height: 400px;
}

.columnReverseFlex {
    display: flex;
    flex-direction: column-reverse;
}

.rowFlex {
    display: flex;
    flex-direction: row;
}

.rowReverseFlex {
    display: flex;
    flex-direction: row-reverse;
}
