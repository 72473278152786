.title {
    margin-bottom: 20px;
}

.responses {
    color: #707579;
    margin-bottom: 25px;
}

.separator {
    border-bottom: 1px solid #e5ebed;
    margin-top: 20px;
    margin-bottom: 10px;
}
