.financialSummary {
    padding: 24px 31px;
    background-color: white;
    border-radius: 5px;
    width: 350px;
    margin-left: 19px;
    margin-bottom: 16px;
}

.title {
    margin-bottom: 22px;
}
