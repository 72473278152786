.title {
    margin-bottom: 20px;
}

.responses {
    color: #707579;
    margin-bottom: 25px;
}

.selectRequester {
    width: 200px;
}
