.reservationDetails {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-bottom: 35px;
    width: 350px;
    padding-left: 25px;
}

.backTitle {
    display: flex;
    margin-left: 20px;
    margin-bottom: 35px;
    width: 632px;
}

.center {
    margin: 0;
    position: absolute;
    top: 10%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
