.tooltipWrapper {
    position: relative;
    display: inline-block;
    margin-top: 27px;
    margin-bottom: 8px;

    .tooltipContentHidden {
        width: 400px;
        padding: 17px;
        border: 1px solid #ccd6da;
        border-radius: 5px;
        border-left: 4px solid #023349;
        background-color: #ffffff;
        visibility: hidden;
        text-align: left;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s;
        left: 50%;
        transform: translateX(-50%);

        &.tooltipContentVisible {
            opacity: 1 !important;
            visibility: visible !important;
            z-index: 10;
        }
    }
}
