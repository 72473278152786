.input {
    box-sizing: border-box;
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    background-color: #ffffff;
    height: 40px;
}

.label {
    margin-bottom: 8px;
}
