p {
    margin: 0 0 6px 0;
    padding: 0;
}

.separator {
    border-bottom: 1px solid #e5ebed;
    margin-top: 20px;
    margin-bottom: 20px;
}

.title {
    margin-bottom: 21px;
}

.btnBox {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 632px;
    margin-left: 19px;
    display: flex;
    justify-content: flex-end;
}

.btnBox button {
    padding: 8px 70px 8px 70px;
}

.btnBox button:hover {
    color: #fff;
    background-color: #0078ab;
}

.reasonOverride {
    margin-top: 1.5rem;
}

.overrideValues {
    color: #707579;
}

.btnOverride {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

.customRefund {
    margin-top: 14px;
}

.titleReason {
    margin-top: 24px;
}

.userInformation {
    margin-top: 20px;
}

.btnUpdate {
    margin-top: 27px;
}

.creditCardInfo {
    margin: 10px 0 30px 0;
}

.modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
    z-index: 10000000 !important;
}

.modalContent button {
    padding: 8px 50px 8px 50px;
    background-color: #6ba342 !important;
    color: #fff;
    border: none;
    margin-bottom: 0.6rem;
    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background-color: #568534 !important;
    }
}

.modalContent a {
    margin-top: 1rem;
}

.modalContent label {
    margin: 2px 0 0 5px !important;
}

.titleMessage {
    word-break: break-word;
}

.descriptionMessage {
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.descriptionMessageOld {
    word-break: break-word;
}

.titleRefundDetails {
    display: flex;
    justify-content: space-between;
}

.rightPanel {
    margin-left: 0;
}
