.btnUpdate {
    margin-top: 27px;
}

.creditCardInfo {
    margin: 10px 0 30px 0;
}

.separator {
    border-bottom: 1px solid #e5ebed;
    margin-top: 20px;
    margin-bottom: 20px;
}
