.textarea {
    box-sizing: border-box;
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 0.5rem;
    padding: 10px;
}

.title {
    size: 16px;
}
