.messageBox {
    width: 500px;
    max-width: 100%;
    background-color: #ffffff;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccd6db;
    padding: 16px;
    box-shadow: 2px 2px #e5eaec;
    border-left-width: 5px;
    color: #2c3439;
    display: flex;
    align-items: center;
    font-size: 1rem;
    word-break: break-all;
    align-self: center;
}

.error {
    border-left-color: #d62e4f;
}

.info {
    border-left-color: #2196f3;
}

.warning {
    border-left-color: #ddad49;
}

.success {
    border-left-color: #6ba342;
}

.alertText {
    padding-left: 0;
}

.messages p {
    margin: 0;
    padding: 0;
}

.icon {
    display: flex;
    opacity: 0.9;
    font-size: 22px;
    margin-right: 18px;
}

.title {
    margin-top: -6px !important;
    margin-bottom: 0.45em !important;
}

.closeIcon {
    margin-left: auto;
    cursor: pointer;
}
