.amountInfoBox {
    background-color: transparent;
}

.amountSection {
    text-align: right;
}

.alignEnd {
    align-items: flex-end;
}

.alignCenter {
    align-items: center;
}

.font {
    color: #2c3439;
    letter-spacing: 0;
}

.fontSmall {
    font-size: 12px;
    line-height: 16px;
}

.fontNormal {
    font-size: 16px;
    line-height: 24px;
    vertical-align: middle;
}

.input {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #b2c2c8;
    width: 126px;
    height: 40px;
    text-align: right;
    padding-right: 5px;
}

.amountText {
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #b2c2c8;
    width: 126px;
    height: 40px;
    text-align: left;
    padding-right: 5px;
    cursor: pointer;
}

textNotEditable {
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    width: 126px;
    height: 40px;
    text-align: right;
    padding-right: 5px;
}

.errorBox {
    border: 1px solid #d62e4f;
}

.errorText {
    color: #d62e4f;
}
