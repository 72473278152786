@import url("https://fonts.googleapis.com/css?family=Public+Sans:400,600,700,400italic");

.emailContainer {
    border-radius: 10px;
    width: 100%;
    font-family: Public Sans, sans-serif;
}

.titleContainer {
    margin-top: 20px;
}
