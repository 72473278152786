.select {
    box-sizing: border-box;
    border: 1px solid #b2c2c8;
    border-radius: 4px;
    width: 290px;
    height: 40px;
    margin-bottom: 27px;
    color: #2c3439;
    font-size: 1rem;
    font-family: Nunito Sans, sans-serif;
    font-weight: 300 !important;
    padding: 5px 5px 5px 16px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat right #ffffff;
    background-position: calc(100% - 0.75rem) center !important;
}

.select:focus {
    outline: 0;
    box-shadow: none;
}

.title {
    size: 16px;
}

.label {
    margin-bottom: 8px;
    color: #2c3439;
    font-size: 1rem;
    font-family: Nunito Sans, sans-serif;
    font-weight: 600 !important;
    margin-top: 12px;
}
