.splitPanel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.splitPanelEditable {
    display: flex;
    justify-content: space-between;
}
