.modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
    z-index: 10000000 !important;
    word-break: break-word;
}

.modalContent button {
    padding: 8px 50px 8px 50px;
    background-color: #6ba342 !important;
    color: #fff;
    border: none;
    margin-bottom: 0.6rem;
    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background-color: #568534 !important;
    }
}

.modalContent a {
    margin-top: 1rem;
}

.modalContent label {
    margin: 2px 0 0 5px !important;
}

.titleMessage {
    word-break: break-word;
}

.descriptionMessage {
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.descriptionMessageOld {
    word-break: break-word;
}

.trashIconError {
    margin-bottom: 7px;
    margin-left: 10px;
    filter: invert(25%) sepia(83%) saturate(2197%) hue-rotate(329deg) brightness(88%) contrast(91%);
}

.buttonsContainer {
    width: 430px;
    margin-top: 10px;
}

.reloadButton {
    margin-left: 10px;
    width: 170px;
    padding: 0;
}

.redirectButton {
    width: 170px;
    padding: 8px 20px 8px 20px !important;
}

.toRegretText {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0078ab !important;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
}

.imgBody {
    max-width: 100%;
    width: 137px;
    margin-bottom: 1rem;
}

.checkNotAsk {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.warningDiv {
    margin-top: 27px;
}
.warningMessage {
    width: 568px;
    height: 46px;
    left: 248px;
    top: 972px;
    background: #faeaed;
    border: 1.25414px solid #d62e4f;
    align-items: center;
    box-sizing: border-box;
    display: flex;
}

.message {
    width: 463px;
    height: 13px;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    font-feature-settings: "salt" on, "ss01" on;
    color: #2c3439;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 10px;
}

.warningIcon {
    color: #d62e4f;
    background: #f5f7f8;
    align-items: center;
}

.warningCircle {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
}

.tpProvider {
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
}
