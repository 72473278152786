.bankCardInfo {
    background-color: transparent;
}

.logoBox {
    border-radius: 4px;
    border: 1px solid #b2c2c8;
    width: 51px;
    height: 31px;
    margin-right: 10px;
    align-self: center;
}

.logo {
    width: 33px;
    margin: auto;
}

.infoBox {
    align-self: center;
}

.font {
    color: #2c3439;
    letter-spacing: 0;
}

.fontSmall {
    font-size: 12px;
    line-height: 16px;
}

.fontNormal {
    font-size: 16px;
    line-height: 24px;
    //vertical-align: middle;
}
