.headerEmail {
    background-color: #e5ebed;
    height: 30%;
    padding: 10px 10px 10px 20px;
    border-radius: 4px 4px 0 0;
    > div {
        margin-bottom: 5px;
        color: #003349;
    }
}

.titleEmails {
    color: #003349;
    font-weight: 600;
}

.emailContacts {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}
